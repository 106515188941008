




























































































import Vue from 'vue';
import moment from 'moment';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Payment } from '../api/payments/payment.model';
import { PaymentStatus } from '../api/payments/payment-status.enum';
import Toast from '../shared/types/toast.class';
import { ToastType } from '../shared/types/toast-type.enum';
import { printDuration } from '../shared/util/date.util';

const paymentsModule = namespace('payments');
const appModule = namespace('app');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {},
})
export default class PayPayment extends Vue {
  loadingPaymentDetails = false;
  isLoading = false;

  @paymentsModule.Getter('current')
  payment!: Payment;

  @appModule.Mutation('addToast')
  addToast!: (toast: Toast) => void;

  @paymentsModule.Action('fetchByToken')
  fetchByToken!: (token: string) => Promise<Payment>;

  @paymentsModule.Action('generateCheckout')
  generateCheckout!: (token: string) => Promise<Payment>;

  get paymentFound() {
    return Boolean(this.payment?._id);
  }

  get bookingFound() {
    return Boolean(this.payment?.booking);
  }

  get amount() {
    return this.payment.amount.toFixed(2);
  }

  get name() {
    return this.payment.booking?.asset?.name;
  }

  get start() {
    return moment(this.payment.booking?.start).format('ddd, DD-MM-YYYY HH:mm');
  }

  get end() {
    return moment(this.payment.booking?.end).format('ddd, DD-MM-YYYY HH:mm');
  }

  get image() {
    return this.payment.booking?.asset?.image.url;
  }

  get duration() {
    if (!this.payment.booking) {
      return 'N/A';
    }
    return printDuration(
      this.payment.booking?.end,
      this.payment.booking?.start,
    );
  }

  get isPaid() {
    return this.payment.status === PaymentStatus.paid;
  }

  get isExpired() {
    return (
      this.payment.status === PaymentStatus.expired ||
      this.payment.status === PaymentStatus.canceled ||
      this.payment.status === PaymentStatus.failed
    );
  }

  get isPending() {
    return this.payment.status === PaymentStatus.pending;
  }

  async onPay() {
    this.loadingPaymentDetails = true;
    try {
      if (
        this.payment.status !== PaymentStatus.open &&
        this.payment.status !== PaymentStatus.pending &&
        this.payment.status !== PaymentStatus.authorized &&
        this.payment.status !== PaymentStatus.paid
      ) {
        await this.generateCheckout(this.$route.params.token);
      }
      window.location.href = this.payment.checkoutUrl;
    } catch (e) {
      this.addToast(
        new Toast(
          `Could not get payment information. Please contact ${this.payment.booking.user.project.email}`,
          ToastType.ERROR,
        ),
      );
    }
    this.loadingPaymentDetails = false;
  }

  async created() {
    if (!this.$route.params.token) {
      return;
    }
    this.isLoading = true;
    await this.fetchByToken(this.$route.params.token);
    this.isLoading = false;
  }
}
